import React, { useEffect } from "react";
import teams from "../assets/images/teams.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";
import {
  CommonHeader,
  AboutCompany,
  Accordion,
  Testimonials,
} from "../components";
import { useTranslation } from "react-i18next";

function AboutPage() {
  const { t } = useTranslation();
  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={t("about.title")}
      subTitle={t("about.subTitle")}
      src={teams}
      alt={"Teams illustration"}
    >
      <AboutCompany />
      <Accordion />
      <Testimonials />
    </CommonHeader>
  );
}

export default AboutPage;
