import React from "react";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
import p3 from "../assets/images/p3.png";
import p4 from "../assets/images/p4.png";

function Process() {
  return (
    <div className="bg-primary py-28 md:px-20">
      <div className="container mx-auto px-4">
        <div className="text-white text-center mb-12">
          <p className="text-gray-300 text-lg md:text-xl font-heading">
            Working Process
          </p>
          <h3 className="font-bold font-heading text-2xl md:text-4xl mb-5 capitalize">
            Engineering digital products from research to production
          </h3>
        </div>
        <div className="relative grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-8 mb-5 overflow-hidden">
          <div className="relative mb-0 text-center">
            <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
            <div
              className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-gray-950
                            "
            >
              <img src={p1} alt="" className="w-8 h-8" />
            </div>
            <div className="text-white">
              <h3 className="text-xl font-semibold font-heading mb-3">
                Research Product
              </h3>
              <p className="text-gray-300 text-sm font-body leading-6">
                Clear understanding of your product dictates what you get is
                what you want.
              </p>
            </div>
          </div>
          <div className="relative mb-0 text-center">
            <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
            <div
              className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--2"
            >
              <img src={p2} alt="" className="w-8 h-8" />
            </div>
            <div className="text-white">
              <h3 className="text-xl font-semibold font-heading mb-3">
                Development
              </h3>
              <p className="text-gray-300 text-sm font-body leading-6">
                Our experienced engineers will meet your requirements using the
                latest industry standards and technologies, fitting your budget
                and timeline.
              </p>
            </div>
          </div>
          <div className="relative mb-0 text-center">
            <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
            <div
              className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--3"
            >
              <img src={p3} alt="" className="w-8 h-8" />
            </div>
            <div className="text-white">
              <h3 className="text-xl font-semibold font-heading mb-3">
                User Testing
              </h3>
              <p className="text-gray-300 text-sm font-body leading-6">
                Your product will be tested in perspective of end-users
                validating the quality of your product features and user
                friendliness.
              </p>
            </div>
          </div>
          <div className="relative mb-0 text-center">
            <div
              className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--4"
            >
              <img src={p4} alt="" className="w-8 h-8" />
            </div>
            <div className="text-white">
              <h3 className="text-xl font-semibold font-heading mb-3">
                Product Handover
              </h3>
              <p className="text-gray-300 text-sm font-body leading-6">
                We will deliver your product on time as you have imagined it,
                from source code to production deployment.
              </p>
            </div>
          </div>
          <div
            className="arrow invisible md:visible absolute translate-y-1/2 -translate-x-1/2 flex items-center 
                        justify-center w-8 h-8 border-2 border-white rounded-full text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
            <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
