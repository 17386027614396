import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import dots from "../assets/images/dots.png";
import ximg from "../assets/images/x.png";
import timg from "../assets/images/t.png";
import oimg from "../assets/images/o.png";
import rimg from "../assets/images/r.png";
import tabs from "../assets/images/tabs.svg";
import { FaCopy, FaTimes } from "react-icons/fa";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { useTranslation } from "react-i18next";

function BlogDetail() {
  useNavbarEffect(true, false);
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openClipboard, setOpenClipboard] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const fetchBlogPost = useCallback(() => {
    setIsLoading(true);
    setIsError(false);

    const file = `/assets/blogs/${id}.md`;

    fetch(file)
      .then((response) => {
        if (!response.ok) {
          throw new Error("File not found");
        }
        return response.text();
      })
      .then((text) => {
        // Extract metadata
        const metadataRegex = /---\n([\s\S]*?)\n---/;
        const metadataMatch = text.match(metadataRegex);
        if (metadataMatch) {
          const metadata = metadataMatch[1]
            .split("\n")
            .filter((line) => line.trim() !== "")
            .reduce((acc, line) => {
              const [key, ...rest] = line.split(": ");
              acc[key.trim()] = rest.join(": ").trim();
              return acc;
            }, {});

          const titleKey = lang === "pl" ? `Title-pl` : "Title";
          const descriptionKey =
            lang === "pl"
              ? `DetailsPageOneLineDescription-pl`
              : "DetailsPageOneLineDescription";

          const authorKey = lang === "pl" ? `Author-pl` : "Author";

          setTitle(metadata[titleKey] || metadata.Title || "Untitled");
          setDescription(
            metadata[descriptionKey] || "No description provided."
          );
          setTags(metadata.Tags ? metadata.Tags.split(", ") : []);
          setAuthor(metadata[authorKey] || "Mereb Technologies Team");
          setDate(metadata.Date || "May 1, 2023");
        }

        const contentRegex = new RegExp(
          `## ${lang}\\s+([\\s\\S]*?)(?=\\n## [a-z]{2}|$)`
        );

        const contentMatch = text.match(contentRegex);
        console.log(contentMatch);
        setContent(
          contentMatch ? contentMatch[1].trim() : "Content not available."
        );
      })
      .catch((error) => {
        console.error("Error fetching blog post:", error);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogPost();
  }, [fetchBlogPost]);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    setOpenClipboard(true);
    setTimeout(() => {
      setOpenClipboard(false);
    }, 3000);
  };

  const handlFileReadError = () => {
    return (
      <div className="w-full h-screen">
        <div className="relative bg-primary py-40 overflow-hidden">
          <div className="container relative z-10 mx-auto px-4">
            <div className="text-white text-center mb-8">
              <h1 className="font-bold font-heading text-4xl md:text-6xl mt-3 mb-5 capitalize">
                {t("insights.error")}
              </h1>
              <p className="text-gray-300 max-w-2xl md:text-lg font-medium font-body mx-auto">
                {t("insights.errorDescription")}
              </p>
              <button
                type="button"
                onClick={fetchBlogPost}
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                {t("insights.retry")}
              </button>
            </div>
          </div>
        </div>
        <div className="mx-auto px-4 py-16 bg-indigo-950 w-full h-full" />
      </div>
    );
  };
  const handleIsLoading = () => {
    return (
      <div className="">
        <div className="relative bg-primary py-40 overflow-hidden">
          <div className="container relative z-10 mx-auto px-4">
            <div className="text-white text-center mb-8">
              <div className="h-12 bg-gray-300 rounded-md w-1/2 mx-auto mb-6 animate-pulse-slow"></div>
              <div className="h-6 bg-gray-200 rounded-md w-3/4 mx-auto mb-4 animate-pulse-slow"></div>
              <div className="h-4 bg-gray-200 rounded-md w-1/4 mx-auto mb-2 animate-pulse-slow"></div>
              <div className="h-6 bg-gray-200 rounded-md w-1/2 mx-auto mb-4 animate-pulse-slow"></div>
            </div>
          </div>
          <div className="absolute top-1/2 left-1/4 animate-grow z-0">
            <img
              src={dots}
              alt="Decorative dots"
              className="max-w-full h-auto"
            />
          </div>
          <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
            <img src={ximg} alt="Letter X" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
            <img src={rimg} alt="Letter R" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
            <img src={oimg} alt="Letter O" className="max-w-full h-auto" />
          </div>
          <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <img
            src={tabs}
            alt="Tabs illustration"
            className="absolute top-1/2 left-1/2 scale-250 -translate-x-1/2 -translate-y-1/2 opacity-10 w-4/6 md:w-2/5 max-h-96"
          />
        </div>
        <div className="container mx-auto px-4 py-16 max-w-6xl">
          <div className="prose prose-lg max-w-none">
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-2/3 mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-1/2 mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return handleIsLoading();
  }

  if (isError) {
    return handlFileReadError();
  }

  return (
    <div>
      <div className="blog-detail">
        <div className="relative bg-primary pt-40 pb-16 overflow-hidden">
          <div className="container relative z-10 mx-auto px-4">
            <div className="text-white text-center mb-8">
              <h1 className="font-bold font-heading  mt-3 mb-5 capitalize blog-title ">
                {title}
              </h1>
              <p className="text-white  max-w-2xl md:text-lg font-medium font-body mx-auto blog-description">
                {description}
              </p>
              {author && date && (
                <p className="text-white  text-sm mb-2">
                  By {author} on {date}
                </p>
              )}
              {tags.length > 0 && (
                <div className="flex flex-wrap mt-4 justify-center">
                  {tags.map((tag, i) => (
                    <span
                      key={i.toString()}
                      className="inline-block rounded-3xl text-sm font-semibold bg-gray-400 text-white m-1 p-2 px-4 "
                    >
                      {tag.trim()}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="absolute top-1/2 left-1/4 animate-grow z-0">
            <img
              src={dots}
              alt="Decorative dots"
              className="max-w-full h-auto"
            />
          </div>
          <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
            <img src={ximg} alt="Letter X" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
            <img src={rimg} alt="Letter R" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
            <img src={oimg} alt="Letter O" className="max-w-full h-auto" />
          </div>
          <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <img
            src={tabs}
            alt="Tabs illustration"
            className="absolute top-1/2 left-1/2 scale-250 -translate-x-1/2 -translate-y-1/2 opacity-10 w-4/6 md:w-2/5 max-h-96"
          />
        </div>
        <div className="container mx-auto px-4 py-16 max-w-6xl">
          <div className="prose prose-lg max-w-none">
            {/* <div className="mb-8 blog-image">
              <img
                src={blogImagePath}
                alt={title}
                className="w-full h-auto rounded-lg shadow-md max-w-6xl mx-auto"
              />
            </div> */}
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.2",
                      marginBottom: "0.3em",
                      fontSize: "1.875rem",
                      "@screen md": { fontSize: "2.25rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h1>
                ),
                h2: ({ node, ...props }) => (
                  <h2
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.3",
                      marginBottom: "0.3em",
                      fontSize: "1.5rem",
                      "@screen md": { fontSize: "1.875rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h2>
                ),
                h3: ({ node, ...props }) => (
                  <h3
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.4",
                      marginBottom: "0.3em",
                      fontSize: "1.25rem",
                      "@screen md": { fontSize: "1.5rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h3>
                ),
                h4: ({ node, ...props }) => (
                  <h4
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.5",
                      marginBottom: "0.3em",
                      // fontWeight: "bold",
                      fontSize: "1.125rem",
                      "@screen md": { fontSize: "1.25rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h4>
                ),
                p: ({ node, ...props }) => (
                  <p
                    style={{
                      lineHeight: "1.6",
                      marginBottom: ".5em",
                      marginTop: ".5em",
                    }}
                    {...props}
                  >
                    {props.children}
                  </p>
                ),
                ul: ({ node, ...props }) => (
                  <ul
                    style={{
                      lineHeight: "1.6",
                      marginBottom: ".5em",
                    }}
                    {...props}
                  >
                    {props.children}
                  </ul>
                ),
                ol: ({ node, ...props }) => (
                  <ol
                    style={{
                      lineHeight: "1.6",
                      marginBottom: ".5em",
                    }}
                    {...props}
                  >
                    {props.children}
                  </ol>
                ),
                li: ({ node, ...props }) => (
                  <li
                    style={{
                      lineHeight: "1.6",
                      marginBottom: ".5em",
                    }}
                    {...props}
                  >
                    {props.children}
                  </li>
                ),
                strong: ({ node, ...props }) => (
                  <strong
                    style={{
                      fontWeight: "800",
                    }}
                    {...props}
                  >
                    {props.children}
                  </strong>
                ),
                a: ({ node, ...props }) => (
                  <a
                    className="text-primary"
                    style={{
                      textDecoration: "none",
                    }}
                    {...props}
                  >
                    {props.children}
                  </a>
                ),
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() =>
                          handleCopy(String(children).replace(/\n$/, ""))
                        }
                        className="absolute top-0 right-0 m-2 p-1 rounded z-10 hover:bg-gray-500 transition-colors bg-slate-300"
                      >
                        <FaCopy />
                      </button>
                      <SyntaxHighlighter
                        style={materialOceanic}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    </div>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {content}
            </ReactMarkdown>{" "}
          </div>
        </div>
      </div>
      {openClipboard && (
        <div className="fixed bottom-0 right-0 p-4 m-4 bg-green-500 text-black rounded-lg shadow-lg z-50 border border-gray-200">
          Copied to clipboard!
          <span
            onKeyDown={() => setOpenClipboard(false)}
            className="float-right ml-2 cursor-pointer"
            onClick={() => setOpenClipboard(false)}
          >
            <FaTimes />
          </span>
        </div>
      )}
    </div>
  );
}

export default BlogDetail;
