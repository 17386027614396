import React, { useEffect } from "react";
import tabs from "../assets/images/tabs.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { CommonHeader, BlogCardGroup } from "../components";
import { useTranslation } from "react-i18next";

function BlogsPage() {
  const { t } = useTranslation();
  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={t("insights.title")}
      subTitle={""}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <div className="">
        <BlogCardGroup />
      </div>
    </CommonHeader>
  );
}

export default BlogsPage;
