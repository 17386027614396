import emailjs from "@emailjs/browser";
import useNavbarEffect from "../hooks/useNavbarEffect";
import React, { useEffect, useState, useRef } from "react";
import { CommonHeader } from "../components";
import uae from "../assets/images/uae.webp";
import usa from "../assets/images/usa.jpg";
import eth from "../assets/images/eth.webp";
import world from "../assets/images/world.svg";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ModelsTable from "../components/modelsTable";
import CardUI from "../components/modelCard";

function ContactPage() {
  useNavbarEffect(true, false);
  const form = useRef();
  const [errors, setErrors] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState("");
  const { t } = useTranslation();

  const locations = [
    {
      name: "Sheridan, Wyoming, USA",
      details: "30 N Gould St, Ste R, Sheridan, WY 82801, USA",
      email: "",
      phone: "+1(307)-206-9767",
      flag: usa,
      color: "from-red-400 to-blue-500",
    },
    {
      name: "Addis Ababa, Ethiopia",
      details: "Noah Complex, Bole, 10th Floor",
      email: "info@mereb.tech",
      phone: "",
      flag: eth,
      color: "from-green-400 to-yellow-400",
    },
    {
      name: "Dubai, UAE",
      details: `Dubai, UAE (${t("contactPage.comingSoon")})`,
      email: "",
      phone: "",
      flag: uae,
      color: "from-red-400 to-green-500",
    },
  ];

  const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } =
    process.env;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader title={t("contactPage.title")} src={world} subTitle={""}>
      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-xl sm:text-1xl md:text-2xl lg:text-2xl xl:text-3xl font-extrabold text-center text-primary mb-8 leading-snug sm:leading-tight md:leading-snug lg:leading-normal">
            {t("contactPage.heroSection.title")}
          </h2>
          <div className="container mx-auto my-3">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {locations.map((location, index) => (
                <div
                  key={index}
                  className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl"
                >
                  <div className="relative h-64 overflow-hidden group">
                    <img
                      src={location.flag}
                      alt={`${location.name} flag`}
                      className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110"
                    />
                    <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-6">
                      <h3
                        className="text-3xl font-bold text-center"
                        style={{
                          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {location.name}
                      </h3>
                    </div>
                    <div className="absolute inset-0 bg-blue-600 bg-opacity-80 flex flex-col justify-center items-center text-white p-6 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500 ease-in-out">
                      <h3
                        className="text-2xl font-bold mb-2 text-center"
                        style={{
                          textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {location.name}
                      </h3>
                      <div className="space-y-2 text-sm text-center">
                        <p className="flex items-center justify-center">
                          <FaMapMarkerAlt className="mr-2" />
                          {location.details}
                        </p>
                        {location.email && (
                          <p className="flex items-center justify-center">
                            <FaEnvelope className="mr-2" />
                            <a href={`mailto:${location.email}`}>
                              {location.email}
                            </a>
                          </p>
                        )}
                        {location.phone && (
                          <p className="flex items-center justify-center">
                            <FaPhoneAlt className="mr-2" />
                            <a href={`tel:${location.phone}`}>
                              {location.phone}
                            </a>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <form
            id="contact-form"
            ref={form}
            onSubmit={onSubmit}
            className=" px-6 border border-gray-200 rounded-md shadow-sm h-full py-6 mx-auto contact-form"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
              {t("contactPage.form.title")}
            </h2>
            <p className="text-gray-600 mb-6">
              {t("contactPage.form.description")}
            </p>

            <div className="mb-4">
              <textarea
                name="message"
                cols="30"
                rows="4"
                className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                placeholder={t("contactPage.form.fields.message.placeholder")}
                disabled={submitted}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <p
                className={`text-sm mt-1 ${
                  errors.message ? "text-red-500" : "text-transparent"
                }`}
              >
                {t("contactPage.form.fields.message.error")}
              </p>
            </div>

            <div className="flex flex-col md:flex-row mb-4 w-full gap-3">
              <div className="flex flex-col w-full md:w-1/2">
                <input
                  name="from_name"
                  type="text"
                  className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                  placeholder={t(
                    "contactPage.form.fields.fullName.placeholder"
                  )}
                  disabled={submitted}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <p
                  className={`text-sm mt-1 ${
                    errors.fullName ? "text-red-500" : "text-transparent"
                  }`}
                >
                  {t("contactPage.form.fields.fullName.error")}
                </p>
              </div>
              <div className="flex flex-col w-full md:w-1/2">
                <input
                  name="reply_to"
                  type="email"
                  className="w-full px-4 py-3 text-gray-800 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-700"
                  placeholder={t("contactPage.form.fields.email.placeholder")}
                  disabled={submitted}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p
                  className={`text-sm mt-1 ${
                    errors.email ? "text-red-500" : "text-transparent"
                  }`}
                >
                  {errors.email &&
                    t(
                      `contactPage.form.fields.email.${
                        errors.email.includes("cannot")
                          ? "errorEmpty"
                          : errors.email.includes("valid")
                          ? "errorInvalid"
                          : ""
                      }`
                    )}
                </p>
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="px-6 py-3 text-lg font-semibold text-white bg-primaryMedium rounded-md hover:bg-blue-100 hover:text-black transition-colors duration-300 w-full"
                disabled={submitted}
              >
                {submitted
                  ? t("contactPage.form.button.loading")
                  : t("contactPage.form.button.default")}
              </button>
              <p
                className={`${
                  response.includes("Successfully")
                    ? `text-green-500 mt-2`
                    : `text-red-500 mt-2`
                }`}
              >
                {response &&
                  t(
                    `contactPage.form.responseMessages.${
                      response.includes("Successfully")
                        ? "success"
                        : response.includes("Error")
                        ? "error"
                        : ""
                    }`
                  )}
              </p>
            </div>
          </form>

          {/* {response && (
            <p
              className={`mt-4 text-sm text-center ${
                response.includes("Successfully")
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {response &&
                t(
                  `contactPage.form.responseMessages.${
                    response.includes("Successfully")
                      ? "success"
                      : response.includes("Error")
                      ? "error"
                      : ""
                  }`
                )}
            </p>
          )} */}
          {/* </div>
          </div> */}
        </div>
      </div>
    </CommonHeader>
  );

  function onSubmit(e) {
    e.preventDefault();
    setResponse("");
    if (!handleValidation()) return;
    setSubmitted(true);

    emailjs
      .sendForm(
        REACT_APP_SERVICE_ID,
        REACT_APP_TEMPLATE_ID,
        form.current,
        REACT_APP_USER_ID
      )
      .then(
        () => {
          setResponse("Successfully submitted!");
          setSubmitted(false);
        },
        (error) => {
          setResponse("Error while submitting!");
          setSubmitted(false);
        }
      );
  }

  function handleValidation() {
    const fields = { fullName, email, message };
    const errs = {};
    let formIsValid = true;
    if (!fields.fullName) {
      formIsValid = false;
      errs.fullName = "Name cannot be empty";
    }
    if (!fields.email) {
      formIsValid = false;
      errs.email = "E-mail cannot be empty";
      const isvalid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
      if (!isvalid) {
        formIsValid = false;
        errs.email = "Please enter a valid e-mail address";
      }
    }
    if (!fields.message) {
      formIsValid = false;
      errs.message = "Message cannot be empty";
    }

    setErrors(errs);
    return formIsValid;
  }
}

export default ContactPage;
