import React, { useLayoutEffect, useState } from "react";
import { FaUsers, FaTasks, FaCode } from "react-icons/fa";
import Modal from "./modal";
import ModelTable from "./modelsTable";
import { useTranslation } from "react-i18next";

const CardUI = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".model-card");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fadeInUp");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    cards.forEach((card) => observer.observe(card));

    return () => {
      observer.disconnect();
    };
  }, []);

  const models = [
    {
      title: t("models.cardsContent.extendTeam.title"),
      icon: <FaUsers className="text-primary text-4xl" />,
      shortDescription: t("models.cardsContent.extendTeam.description"),
    },
    {
      title: t("models.cardsContent.managedTeam.title"),
      icon: <FaTasks className="text-primary text-4xl" />,
      shortDescription: t("models.cardsContent.managedTeam.description"),
    },
    {
      title: t("models.cardsContent.customSolution.title"),
      icon: <FaCode className="text-primary text-4xl" />,
      shortDescription: t("models.cardsContent.customSolution.description"),
    },
  ];

  return (
    <div className="bg-gradient-to-b my-5">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent">
            {t("models.title")}
          </h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {models.map((model, index) => (
            <div
              key={index}
              className="model-card group relative bg-white rounded-2xl shadow-md p-8 opacity-0 translate-y-10 
              hover:shadow-2xl transition-all duration-300 border border-gray-100 hover:border-primary hover:-translate-y-2 flex flex-col"
              style={{ animationDelay: `${index / 9}s` }}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-primary/10 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>

              <div className="relative flex-grow">
                <div className="w-16 h-16 mx-auto bg-primary/10 rounded-2xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                  <div className="text-primary w-8 h-8">{model.icon}</div>
                </div>

                <h3 className="text-2xl font-bold text-gray-800 mt-6 mb-4">
                  {model.title}
                </h3>

                <p className="text-gray-600 mb-8 leading-relaxed">
                  {model.shortDescription}
                </p>
              </div>

              <button
                onClick={() => setIsModalOpen(true)}
                className="relative z-10 w-full bg-gradient-to-r from-primary to-primary/90 text-white py-3 rounded-xl font-semibold 
                hover:shadow-lg hover:shadow-primary/30 transition-all duration-300 flex items-center justify-center gap-2 mt-auto cursor-pointer"
              >
                {t("models.cardsContent.learnMore")}
                <svg
                  className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </button>

              <div className="absolute bottom-0 left-0 w-full h-1 bg-primary rounded-b-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
          ))}
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModelTable />
      </Modal>
    </div>
  );
};

export default CardUI;
