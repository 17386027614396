import React, { useState, useEffect } from "react";
import tabs from "../assets/images/tabs.svg";
import { FaCopy } from "react-icons/fa";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams } from "react-router-dom";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { CommonHeader } from "../components";
import dots from "../assets/images/dots.png";
import ximg from "../assets/images/x.png";
import timg from "../assets/images/t.png";
import oimg from "../assets/images/o.png";
import rimg from "../assets/images/r.png";
import { useTranslation } from "react-i18next";

function DetailedJobView() {
  useNavbarEffect(true, false);
  // check which lang is selected
  const { i18n } = useTranslation();
  const lang = i18n.language;

  // const form = useRef();
  const Id = useParams().id;
  const [content, setContent] = useState("");
  const markdownFile = `/assets/jobs/jobPost${Id}.md`;
  const [title, setTitle] = useState("");

  // const [fullName, setFullName] = useState("");
  // const [cvFile, setCvFile] = useState(null);
  // const [uploading, setUploading] = useState(false);
  // const [uploadSuccess, setUploadSuccess] = useState(false);
  // const [position, setPosition] = useState("");
  // const [resume, setResume] = useState("");
  // const [response, setResponse] = useState("");
  // const [errors, setErrors] = useState({});
  // const [phone, setPhone] = useState("");
  // let { state } = useLocation();
  // const selectedPosition = state?.title;
  const [isLoading, setIsLoading] = useState(false);

  // const {
  //   REACT_APP_SERVICE_ID,
  //   REACT_APP_TEMPLATE_JOBS_ID,
  //   REACT_APP_USER_ID,
  // } = process.env;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(markdownFile);
        const text = await response.text();

        // Split the markdown by language section
        const sections = text.split("---");
        const langSection = sections.find((section) =>
          section.trim().startsWith(`## ${lang}`)
        );

        if (langSection) {
          // Extract the title and content
          const lines = langSection.split("\n").filter((line) => line.trim());
          const firstLine = lines.find((line) => line.startsWith("# "));
          setTitle(firstLine ? firstLine.replace("# ", "") : "Job Description");
          setContent(lines.slice(lines.indexOf(firstLine) + 1).join("\n"));
        } else {
          console.warn(`No content available for language: ${lang}`);
        }
      } catch (error) {
        console.error("Error fetching markdown file:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [markdownFile, lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleFileChange = (e) => {
  //   setCvFile(e.target.files[0]);
  //   setUploadSuccess(false);
  // };

  // const handleUpload = () => {
  //   setUploading(true);
  //   setTimeout(() => {
  //     setUploading(false);
  //     setUploadSuccess(true);
  //   }, 2000);
  // };

  // const handleValidation = () => {
  //   let errors = {};
  //   let formIsValid = true;
  //   if (!fullName) {
  //     formIsValid = false;
  //     errors["fullName"] = "Please provide your full name";
  //   }
  //   if (!cvFile) {
  //     formIsValid = false;
  //     errors["cvFile"] = "Please upload your CV";
  //   }
  //   if (!position) {
  //     formIsValid = false;
  //     errors["position"] = "Please select a position";
  //   }
  //   if (!resume) {
  //     formIsValid = false;
  //     errors["resume"] = "Please provide additional information";
  //   }
  //   if (!phone || !/^\d{10}$/.test(phone)) {
  //     formIsValid = false;
  //     errors["phone"] = "Please provide your phone number";
  //   }
  //   setErrors(errors);
  //   return formIsValid;
  // };

  // function onSubmit(e) {
  //   e.preventDefault();
  //   setResponse("");
  //   console.log("Form submitted!", handleValidation());
  //   if (!handleValidation()) return;
  //   const message = `Position Applied For: ${position}\n\n Phone: ${phone}\n\n Additional Information: ${resume}`;
  //   const emailParams = {
  //     from_name: fullName,
  //     to_name: "HR",
  //     message: message,
  //     file: cvFile,
  //   };
  //   emailjs
  //     .sendForm(
  //       REACT_APP_SERVICE_ID,
  //       REACT_APP_TEMPLATE_JOBS_ID,
  //       emailParams,
  //       REACT_APP_USER_ID
  //     )
  //     .then(
  //       () => {
  //         console.log("Application submitted successfully!");
  //         setResponse("Application submitted successfully!");
  //         setFullName("");
  //         setCvFile(null);
  //         setPosition("");
  //         setResume("");
  //         setUploadSuccess(false);
  //       },
  //       (error) => {
  //         console.error(error);
  //         setResponse("Error while submitting application!");
  //       }
  //     );
  // }

  if (isLoading) {
    return (
      <div className="">
        <div className="relative bg-primary py-40 overflow-hidden">
          <div className="container relative z-10 mx-auto px-4">
            <div className="text-white text-center mb-8">
              <div className="h-12 bg-gray-300 rounded-md w-1/2 mx-auto mb-6 animate-pulse-slow"></div>
              <div className="h-6 bg-gray-200 rounded-md w-3/4 mx-auto mb-4 animate-pulse-slow"></div>
              <div className="h-4 bg-gray-200 rounded-md w-1/4 mx-auto mb-2 animate-pulse-slow"></div>
              <div className="h-6 bg-gray-200 rounded-md w-1/2 mx-auto mb-4 animate-pulse-slow"></div>
            </div>
          </div>
          <div className="absolute top-1/2 left-1/4 animate-grow z-0">
            <img
              src={dots}
              alt="Decorative dots"
              className="max-w-full h-auto"
            />
          </div>
          <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
            <img src={ximg} alt="Letter X" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
            <img src={rimg} alt="Letter R" className="max-w-full h-auto" />
          </div>
          <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
            <img src={oimg} alt="Letter O" className="max-w-full h-auto" />
          </div>
          <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
            <img src={timg} alt="Letter T" className="max-w-full h-auto" />
          </div>
          <img
            src={tabs}
            alt="Tabs illustration"
            className="absolute top-1/2 left-1/2 scale-250 -translate-x-1/2 -translate-y-1/2 opacity-10 w-4/6 md:w-2/5 max-h-96"
          />
        </div>
        <div className="container mx-auto px-4 py-16 max-w-6xl">
          <div className="prose prose-lg max-w-none">
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-2/3 mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-1/2 mb-4 animate-pulse-slow"></div>
            <div className="h-4 bg-gray-200 rounded-md w-full mb-4 animate-pulse-slow"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <CommonHeader
      title={`${title ? title : ""} Job Application`}
      subTitle={""}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <div className="">
        <div className="container mx-auto px-4 py-3 max-w-6xl">
          <div
            className="prose prose-lg max-w-none mb-8"
            // style={{
            //   fontFamily: "Helvetica Neue",
            //   lineHeight: "1.5",
            // }}
          >
            <ReactMarkdown
              // style={{
              //   fontFamily: "Helvetica Neue",
              // }}
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.2",
                      marginBottom: "0.3em",
                      // fontWeight: "900",
                      fontSize: "2.69rem",
                      marginTop: "2rem",
                      "@screen md": { fontSize: "2.25rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h1>
                ),
                h2: ({ node, ...props }) => (
                  <h2
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.3",
                      marginBottom: "0.3em",
                      fontSize: "1.5rem",
                      "@screen md": { fontSize: "1.875rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h2>
                ),
                h3: ({ node, ...props }) => (
                  <h3
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.4",
                      marginBottom: "0.3em",
                      fontSize: "1.25rem",
                      "@screen md": { fontSize: "1.5rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h3>
                ),
                h4: ({ node, ...props }) => (
                  <h4
                    className="font-bold font-heading"
                    style={{
                      lineHeight: "1.5",
                      marginBottom: "0.3em",
                      fontSize: "1.125rem",
                      "@screen md": { fontSize: "1.25rem" },
                    }}
                    {...props}
                  >
                    {props.children}
                  </h4>
                ),
                p: ({ node, ...props }) => (
                  <p
                    style={{
                      lineHeight: "1.6",
                      marginBottom: "1em",
                    }}
                    {...props}
                  >
                    {props.children}
                  </p>
                ),
                a: ({ node, ...props }) => (
                  <a
                    className="text-primary"
                    style={{
                      textDecoration: "none",
                    }}
                    target="_blank"
                    {...props}
                  >
                    {props.children}
                  </a>
                ),
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            String(children).replace(/\n$/, "")
                          )
                        }
                        className="absolute top-0 right-0 m-2 p-1 rounded z-10 hover:bg-gray-500 transition-colors bg-slate-300"
                      >
                        <FaCopy />
                      </button>
                      <SyntaxHighlighter
                        style={materialOceanic}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    </div>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
          {/* {title !== "Game Designer" && title !== "Proposal Writer" ? (
            <form
              ref={form}
              onSubmit={onSubmit}
              className="bg-white p-8 rounded-lg shadow-md space-y-6"
            >
              <div className="flex flex-col space-y-2">
                <label className="text-lg font-medium text-gray-700">
                  Full Name
                </label>
                <input
                  type="text"
                  name="from_name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <p className="text-sm text-red-500">{errors.fullName}</p>
              </div>

              <div className="flex flex-col space-y-2">
                <label className="text-lg font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <p className="text-sm text-red-500">{errors.phone}</p>
              </div>

              <div className="flex flex-col space-y-2">
                <label className="text-lg font-medium text-gray-700">
                  Upload CV
                </label>
                <div className="relative">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100 cursor-pointer"
                  />
                </div>
                {uploading && (
                  <div className="flex items-center text-blue-500 mt-2">
                    <FaFileUpload className="mr-2 animate-bounce" />{" "}
                    Uploading...
                  </div>
                )}
                {uploadSuccess && (
                  <div className="flex items-center text-green-500 mt-2">
                    <FaCheckCircle className="mr-2" /> Upload Complete
                  </div>
                )}
                {!uploading && cvFile && !uploadSuccess && (
                  <button
                    type="button"
                    onClick={handleUpload}
                    className="mt-2 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Upload CV
                  </button>
                )}
                <p className="text-sm text-red-500">{errors.cvFile}</p>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-lg font-medium text-gray-700">
                  Position Applied For
                </label>
                <select
                  defaultValue={selectedPosition}
                  onChange={(e) => setPosition(e.target.value)}
                  className="px-4 py-2 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value={selectedPosition} disabled>
                    {selectedPosition}
                  </option>
                </select>
                <p className="text-sm text-red-500">{errors.position}</p>
              </div>

              <div className="flex flex-col space-y-2">
                <label className="text-lg font-medium text-gray-700">
                  Additional Information
                </label>
                <textarea
                  name="message"
                  value={resume}
                  onChange={(e) => setResume(e.target.value)}
                  rows="6"
                  className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Share any additional information or cover letter"
                />
                <p className="text-sm text-red-500">{errors.resume}</p>
              </div>

              <button
                type="submit"
                className="w-full bg-primary text-white px-4 py-2 rounded-lg hover:bg-primaryMedium transition"
              >
                Submit Application
              </button>
              <p
                className={`text-center mt-2 ${
                  response.includes("successfully")
                    ? `text-green-500`
                    : `text-red-500`
                }`}
              >
                {response}
              </p>
            </form>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    </CommonHeader>
  );
}

export default DetailedJobView;
