import React from "react";
import office from "../assets/teams/group_photo.jpg";
import { useTranslation } from "react-i18next";

function AboutCompany() {
  const { t } = useTranslation();
  return (
    <div className="bg-indigo-50 py-24">
      <div className="container w-full mx-auto px-10 flex flex-wrap flex-row justify-between items-center">
        <div className="mb-8">
          <h2 className="text-primary text-3xl md:text-5xl font-heading">
            {t("about.sections.ourCompany.title")}
          </h2>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-3 mb-3 md:max-w-2xl capitalize">
            {t("about.subTitle")}
          </h3>
          <p className="text-gray-700  about-section-p mx-auto mt-4 font-body">
            {t("about.sections.ourCompany.descriptionOne")}
          </p>
          <p className="text-gray-700 about-section-p mx-auto mt-4 font-body">
            {t("about.sections.ourCompany.descriptionTwo")}
          </p>
          <p className="text-gray-700 about-section-p mx-auto mt-4">
            {t("about.sections.ourCompany.descriptionThree")}
          </p>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-10 mb-3 capitalize">
            {t("about.sections.ourVision.title")}
          </h3>
          <p className="text-gray-700 about-section-p mx-auto mt-4 font-body">
            {t("about.sections.ourVision.description")}
          </p>
          <h3 className="text-black font-bold font-heading text-2xl md:text-2xl mt-10 mb-3 capitalize">
            {t("about.sections.ourGoals.title")}
          </h3>
          <ul className="list-disc list-inside text-gray-700  about-section-p mx-auto mt-4 font-body">
            <li>{t("about.sections.ourGoals.descriptionOne")}</li>
            <li>{t("about.sections.ourGoals.descriptionTwo")}</li>
            <li>{t("about.sections.ourGoals.descriptionThree")}</li>
            <li>{t("about.sections.ourGoals.descriptionFour")}</li>
          </ul>
        </div>
        <div className="relative group w-2/5 about-img-section mx-auto md:mx-0 md:mt-2">
          <div className="invisible img-wrap  transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-primary w-3/5 h-28 rounded" />
          <div className="invisible img-wrap  transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-black w-3/5 h-28 rounded" />
          <img
            src={office}
            alt="office"
            title="office image"
            className="relative w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutCompany;
