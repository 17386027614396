import React, { useEffect, useRef, useState } from "react";
import frontend_team from "../assets/teams/frontend_team.webp";
import backend_team from "../assets/teams/backend_team.webp";
import project_management_team from "../assets/teams/project_managers.webp";
import neb_img from "../assets/teams/management/neb_ceo.webp";
import yar_img from "../assets/teams/management/yar_coo.webp";
import sam_img from "../assets/teams/management/sam_cto1.webp";
import kalkidan_img from "../assets/teams/management/kalkidan1.webp";
import tinbite_img from "../assets/teams/management/tinbite.webp";
import betelhem_img from "../assets/teams/management/betelhem.webp";
import teams from "../assets/images/teams.svg";
import linkedin from "../assets/images/linkedin.png";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { CommonHeader } from "../components";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function OurTeam() {
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  const handleBioClick = (leader) => {
    setSelectedLeader(leader);
    document.body.style.overflow = "hidden";
  };

  const handleCloseBio = () => {
    setSelectedLeader(null);
    document.body.style.overflow = "auto";
  };
  useNavbarEffect(true, false);
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("team-animate-moveup");
        }
      });
    });
    const currentRefs = sectionRefs.current;
    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const teamData = {
    leadership: [
      {
        name: "Nebyu Zakariyas",
        position: t("ourTeam.leadership.nebyu.position"),
        photo: neb_img,
        bio: t("ourTeam.leadership.nebyu.bio"),
        url: "https://www.linkedin.com/in/nebyu-zakariyas-08053215a/",
      },
      {
        name: "Yared Taddese",
        position: t("ourTeam.leadership.yared.position"),
        photo: yar_img,
        bio: t("ourTeam.leadership.yared.bio"),
        url: "https://www.linkedin.com/in/yared-taddese/",
      },
      {
        name: "Samuel Mussie",
        position: t("ourTeam.leadership.samuel.position"),
        photo: sam_img,
        bio: t("ourTeam.leadership.samuel.bio"),
        url: "https://www.linkedin.com/in/samu-el/",
      },
      {
        name: "Tinbite Solomon",
        position: t("ourTeam.leadership.tinbite.position"),
        photo: tinbite_img,
        bio: t("ourTeam.leadership.tinbite.bio"),
        url: "https://www.linkedin.com/in/tinbite-solomon/",
      },
      {
        name: "Kalkidan Tadesse",
        position: t("ourTeam.leadership.kalkidan.position"),
        photo: kalkidan_img,
        bio: t("ourTeam.leadership.kalkidan.bio"),
        url: "https://www.linkedin.com/in/kalkidan-tadesse-a5377b184/",
      },
      {
        name: "Betelhem Abebe",
        position: t("ourTeam.leadership.betelhem.position"),
        photo: betelhem_img,
        bio: t("ourTeam.leadership.betelhem.bio"),
        url: "https://www.linkedin.com/in/betelhem-abebe-05b82019a/",
      },
    ],
  };
  return (
    <CommonHeader
      title={t("ourTeam.title")}
      subTitle={""}
      src={teams}
      alt={"Teams illustration"}
    >
      <div className="overflow-hidden">
        <section className="w-fit mx-auto py-20 flex-grow">
          <div className=" ml-10rem mr-10rem grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 justify-center">
            {teamData.leadership.map((member, index) => (
              <div
                key={index}
                className="bg-white p-4 rounded-lg mx-auto object-fill max-w-[270px]"
              >
                <div className="relative">
                  <img
                    src={member.photo}
                    alt={member.name}
                    onLoad={() => setIsLoaded(true)}
                    className={`w-full h-[20rem] rounded-lg mb-4 object-cover transition-opacity duration-500 ${
                      isLoaded ? "opacity-100" : "opacity-0"
                    }`}
                  />
                  {!isLoaded && (
                    <div className="absolute inset-0 flex flex-col justify-center items-center bg-gray-300 animate-pulse rounded-lg w-[190px] h-[20rem]"></div>
                  )}
                </div>
                <div className="w-full flex justify-between items-center">
                  <h3 className="text-xl font-bold">{member.name}</h3>
                </div>
                <p className="text-primaryMedium font-semibold">
                  {member.position}
                </p>
                <button
                  className="text-blue-800 mt-4 font-semibold flex items-center transition hover:gap-1 ease-in-out"
                  onClick={() => handleBioClick(member)}
                >
                  {t("ourTeam.buttons.readBio")}
                  <FaArrowRight className="inline-block ml-1" />
                </button>
              </div>
            ))}
          </div>
        </section>
        {selectedLeader && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-1200 leader-aside-section">
            <aside className="w-96 bg-white shadow-lg fixed top-0 right-0 h-screen z-1201 leader-aside-section">
              <button
                onClick={handleCloseBio}
                className="absolute top-4 right-4 text-black font-bold"
              >
                {t("ourTeam.buttons.close")}
              </button>
              <div className="p-8 overflow-y-auto h-full mt-4">
                <img
                  src={selectedLeader.photo}
                  alt={selectedLeader.name}
                  className="w-full  object-fit rounded-lg mb-4"
                />
                <h3 className="text-2xl font-bold mb-2">
                  {selectedLeader.name}
                </h3>
                <p className="text-primaryMedium mb-2">
                  {selectedLeader.position}
                </p>
                <a
                  className="w-8 h-8 border-gray-200 border-[1px] shadow-sm rounded-full flex items-center justify-center mb-3"
                  href={selectedLeader.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="linkedin icon"
                    className="w-5 cursor-pointer"
                  />
                </a>
                <p className="text-gray-700">{selectedLeader.bio}</p>
              </div>
            </aside>
          </div>
        )}
        <section
          ref={(el) => (sectionRefs.current[1] = el)}
          className="bg-gray-100 py-20  px-5 team-card"
        >
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 md:pr-12 ">
              <h2 className="text-3xl font-bold mb-4 relative">
                <span className="text-gray-800 ">
                  {t("ourTeam.teams.frontend.title")}{" "}
                </span>
                <div className="absolute -bottom-2 left-0 w-[100px] underlineEffect"></div>
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                {t("ourTeam.teams.frontend.description")}
              </p>
            </div>
            <div className="relative group w-full md:w-2/5">
              <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-merebBrandColor w-3/5 h-28 rounded" />
              <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-black w-3/5 h-28 rounded" />

              <img
                src={frontend_team}
                alt="Front-End Team"
                className="rounded-lg shadow-lg relative w-full"
              />
            </div>
          </div>
        </section>
        <section
          ref={(el) => (sectionRefs.current[2] = el)}
          className="py-20  px-5 team-card"
        >
          <div className="container mx-auto flex flex-wrap-reverse flex-row items-center">
            <div className="relative group w-full md:w-2/5">
              <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-black w-3/5 h-28 rounded" />
              <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-merebBrandColor w-3/5 h-28 rounded" />

              <img
                src={backend_team}
                alt="Back-End Team"
                className="rounded-lg shadow-lg relative w-full"
              />
            </div>
            <div className="md:w-1/2 md:pl-12 ">
              <h2 className="text-3xl font-bold mb-4 relative">
                <span className="text-gray-800">
                  {t("ourTeam.teams.backend.title")}
                </span>
                <div className="absolute -bottom-2 left-0 w-[100px] underlineEffect"></div>
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                {t("ourTeam.teams.backend.description")}
              </p>
            </div>
          </div>
        </section>
        <section
          ref={(el) => (sectionRefs.current[3] = el)}
          className="bg-gray-100 py-20 px-5 team-card"
        >
          <div className="container mx-auto flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 md:pr-12 ">
              <h2 className="text-3xl font-bold mb-4 relative">
                <span className="text-gray-800">
                  {t("ourTeam.teams.projectManagement.title")}
                </span>
                <div className="absolute -bottom-2 left-0 w-[100px] underlineEffect"></div>
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                {t("ourTeam.teams.projectManagement.description")}
              </p>
            </div>
            <div className="relative group w-full md:w-2/5 ">
              <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-merebBrandColor w-3/5 h-28 rounded" />
              <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-black w-3/5 h-28 rounded" />

              <img
                src={project_management_team}
                alt="Project Management Team"
                className="rounded-lg shadow-lg relative w-full"
              />
            </div>
          </div>
        </section>
      </div>
    </CommonHeader>
  );
}

export default OurTeam;
