import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Work({ index, content }) {
  const { t } = useTranslation();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription((prevState) => !prevState);
  };

  const truncatedDescription =
    content.description.length > 100
      ? content.description.slice(0, 100) + "... "
      : content.description;

  return (
    <div
      style={{ animationDelay: index / 5 + "s" }}
      className="work group flex flex-col w-full rounded-lg opacity-0 overflow-hidden transition-shadow shadow hover:shadow-md "
    >
      <div className="relative after:absolute after:top-0 after:w-full after:h-full after:bg-black/10 overflow-hidden h-72">
        <img
          src={content.imageTop}
          alt={content.title}
          className={`w-full min-h-full transition-transform duration-500 group-hover:scale-105 group-focus:scale-105 
           object-center object-cover`}
        />
      </div>
      <div className="flex flex-col p-8 bg-white flex-1">
        <Link
          to={`#/portfolio/${content.link}`}
          className="max-w-full text-indigo-750 transition-colors text-xl font-bold font-heading"
        >
          {content.title}
        </Link>
        <p className="max-w-full text-justify font-body text-gray-500 my-4">
          {showFullDescription ? content.description : truncatedDescription}
          {content.description.length > 100 && (
            <button
              onClick={toggleDescription}
              className="text-blue-700 font-medium px-1"
            >
              {showFullDescription
                ? t("projects.readLess")
                : t("projects.readMore")}
            </button>
          )}
        </p>
        {content.imageIcons.length >= 4 ? (
          <div className="grid grid-cols-4 gap-1 justify-center">
            {content.imageIcons.map((icon, i) => (
              <div
                key={i}
                className="flex justify-center items-center m-1 font-medium py-1 px-1 bg-white rounded-full text-gray-700"
              >
                <a id="site" href={icon.url}>
                  <div className="font-body flex flex-col items-center leading-none max-w-full flex-initial">
                    <img src={icon.img} alt="" className="w-auto h-9" />
                    <p
                      htmlFor="#site"
                      className="font-semibold px-1.5 font-heading text-sm whitespace-nowrap mt-1"
                    >
                      {icon.title}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-1 justify-center">
            {content.imageIcons.map((icon, i) => (
              <div
                key={i}
                className="flex justify-center items-center m-1 font-medium py-1 px-1 bg-white rounded-full text-gray-700"
              >
                <a id="site" href={icon.url}>
                  <div className="font-body flex flex-col items-center leading-none max-w-full flex-initial">
                    <img
                      src={icon.img}
                      alt={icon.title}
                      className="w-auto h-9"
                    />
                    <p
                      htmlFor="#site"
                      className="font-semibold px-1.5 font-heading text-sm"
                    >
                      {icon.title}
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        )}

        <div className="mt-auto flex flex-col items-center justify-between">
          <div className="mt-2 px-3 py-0.5 rounded-2xl bg-gray-200">
            <span className="font-heading font-normal text-sm text-center text-gray-700">
              {content.category}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
