import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo-3.png";
import { FaPodcast } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";

function Navbar() {
  const [open, setOpen] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageMenuOpen(false); // Close the language menu after selection
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 868) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="animate-pulldown navbar fixed top-0 left-0 -translate-y-full z-1200 w-full">
      <div
        className={`w-full text-white px-20 navbar-responsive-main ${
          open ? "bg-primary" : ""
        }`}
      >
        <div className="flex flex-col navbar-responsive  mx-auto custom:items-center custom:justify-between custom:flex-row custom:py-2">
          <div className="p-3 flex flex-row items-center custom:px-0 justify-between">
            <Link onClick={clickMenuButton} to="/" className="text-2xl">
              <img
                src={logo}
                alt="Mereb Technologies logo - Software Development and Outsourcing"
                className="w-32 custom:w-40 min-w-[70px] min-h-[25px]"
                width="256"
                height="128"
              />
            </Link>
            <button
              aria-label="Open Menu"
              type="button"
              className={`hamburger custom:hidden rounded-lg focus:outline-none focus:shadow-outline ${
                open ? "open" : ""
              }`}
              onClick={() => setOpen(!open)}
            >
              <div className="line line1" />
              <div className="line line2" />
              <div className="line line3" />
            </button>
          </div>
          <nav
            className={`${
              open ? "h-49 pb-4 visible bg-primary " : "h-0 pb-0 invisible"
            } transition-all duration-350 flex flex-col flex-grow custom:h-full custom:pb-0 custom:flex custom:justify-end custom:flex-row
						`}
          >
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/"
            >
              {t("navbar.home")}
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/portfolio"
            >
              {t("navbar.projects")}
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/about"
            >
              {t("navbar.about")}
            </NavLink>

            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/our-team"
            >
              {t("navbar.ourTeam")}
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/insights"
            >
              {t("navbar.insights")}
            </NavLink>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/jobs"
            >
              {t("navbar.career")}
            </NavLink>
            <a
              href="https://podcast.mereb.tech"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setOpen(false)}
              className={`${
                open ? "opacity-100 visible text-white" : "opacity-0 invisible"
              } flex flex-row  items-center font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline`}
            >
              {t("navbar.podcast")}
              <span className="text-xs ml-[.2]">
                <FaPodcast />
              </span>
            </a>
            <NavLink
              onClick={() => setOpen(false)}
              className={({ isActive }) =>
                `${
                  open
                    ? "opacity-100 visible text-white"
                    : "opacity-0 invisible"
                } font-heading whitespace-nowrap transition-opacity duration-500 custom:visible custom:opacity-100 px-1 py-1 mt-2 mx-1 text-xl bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 custom:mt-0 custom:ml-4 customUnderline ${
                  isActive ? "customUnderline-active" : ""
                }`
              }
              to="/contact"
            >
              {t("navbar.contact")}
            </NavLink>
            <div className="relative mt-2 block custom:hidden">
              <button
                className="cursor-pointer
              flex items-center px-4 py-2 text-white  rounded-lg hover:bg-blue-700 focus:outline-none"
                onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
              >
                <FaGlobe className="mr-2" />
                {t("language")}
              </button>
              {languageMenuOpen && (
                <ul className="absolute right-0 mt-2 w-30 bg-blue-600 border rounded-lg shadow-lg">
                  <li
                    className="px-4 py-2 hover:bg-blue-500 cursor-pointer"
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </li>
                  <li
                    className="px-4 py-2 hover:bg-blue-500 cursor-pointer"
                    onClick={() => changeLanguage("pl")}
                  >
                    Polish
                  </li>
                </ul>
              )}
            </div>
          </nav>
          {/* Language Button Outside the Hamburger Menu */}
          <div className="relative mt-4 custom:mt-0 custom:ml-0 hidden custom:block">
            <button
              className="cursor-pointer
              flex items-center px-4 py-2 text-white  rounded-lg hover:bg-blue-700 focus:outline-none"
              onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
            >
              <FaGlobe className="mr-2" />
              {t("language")}
            </button>
            {languageMenuOpen && (
              <ul className="absolute right-0 mt-2 w-30 bg-blue-600 border rounded-lg shadow-lg">
                <li
                  className="px-4 py-2 hover:bg-blue-500 cursor-pointer"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </li>
                <li
                  className="px-4 py-2 hover:bg-blue-500 cursor-pointer"
                  onClick={() => changeLanguage("pl")}
                >
                  Polish
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );

  function clickMenuButton() {
    if (window.innerWidth > 768) return;
    const menuRef = document.querySelector(".menu-btn");
    menuRef.click();
  }
}

export default Navbar;
