import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n"; // Import i18n configuration

const spinner = (
  <div className="flex justify-center items-center h-screen">
    <div className="w-10 h-10 bg-blue-500 rounded-full animate-ping"></div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={spinner}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
