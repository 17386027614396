import React, { useState } from "react";
import office from "../assets/teams/mereb_group_p.png";
import meeting from "../assets/images/meeting.png";
import chip from "../assets/images/chip.png";
import like from "../assets/images/like.png";
import best from "../assets/images/best.png";
import commitment from "../assets/images/commitment.png";
import { useTranslation } from "react-i18next";

function Accordion({ classes }) {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1);

  return (
    <section className={`acc-cont ${classes || ""}`}>
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-8">
          <h2 className="text-primary text-3xl md:text-5xl font-heading">
            {t("accordion.title")}
          </h2>
        </div>
        <div className="container  mx-auto px-4 flex flex-col md:flex-row py-8 justify-evenly items-center">
          <div className="relative group w-full md:w-2/5 ">
            <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-black w-3/5 h-20 rounded" />
            <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-primary w-3/5 h-20 rounded" />

            <img
              src={office}
              alt="a"
              className=" select-none rounded-lg shadow-lg relative w-full self-center"
            />
          </div>
          <ul className="list-none mt-14 md:mt-0 md:w-2/5">
            <li
              onKeyDown={() => indexHandler(1)}
              onClick={() => indexHandler(1)}
              className={`${
                index === 1 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 1
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={commitment} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    {t("accordion.features.longTerm.title")}
                  </h2>
                </div>
                <div
                  className={`${
                    index === 1
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 1 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                {t("accordion.features.longTerm.description")}
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(2)}
              onClick={() => indexHandler(2)}
              className={`${
                index === 2 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 2
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={meeting} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    {t("accordion.features.greatUnderstanding.title")}
                  </h2>
                </div>
                <div
                  className={`${
                    index === 2
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 2 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                {t("accordion.features.greatUnderstanding.description")}
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(3)}
              onClick={() => indexHandler(3)}
              className={`${
                index === 3 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 3
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={chip} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    {t("accordion.features.cuttingEdge.title")}
                  </h2>
                </div>
                <div
                  className={`${
                    index === 3
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 3 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                {t("accordion.features.cuttingEdge.description")}
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(4)}
              onClick={() => indexHandler(4)}
              className={`${
                index === 4 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 4
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={like} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    {t("accordion.features.experiencedTeam.title")}
                  </h2>
                </div>
                <div
                  className={`${
                    index === 4
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 4 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}
              >
                {t("accordion.features.experiencedTeam.description")}
              </div>
            </li>
            <li
              onKeyDown={() => indexHandler(5)}
              onClick={() => indexHandler(5)}
              className={`${
                index === 5 ? "shadow-md" : "shadow-lg"
              } mb-8 rounded-lg border border-gray-200 cursor-pointer`}
            >
              <div
                className={`${
                  index === 5
                    ? "bg-primary text-white"
                    : "bg-white text-indigo-950"
                }
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}
              >
                <div className="flex items-center">
                  <div className="p-1.5 mr-3 bg-pink-50 rounded-sm">
                    <img src={best} alt="" className="w-7 h-7" />
                  </div>
                  <h2 className="font-medium text-lg font-heading">
                    {t("accordion.features.bestQuality.title")}
                  </h2>
                </div>
                <div
                  className={`${
                    index === 5
                      ? "text-white rotate-135"
                      : "text-black rotate-0"
                  } select-none text-2xl transition-all`}
                >
                  +
                </div>
              </div>
              <div
                className={`${
                  index === 5 ? "max-h-48 py-4" : "max-h-0 py-0"
                } bg-white font-body overflow-hidden px-4 
                            transition-all duration-500 text-gray-800`}
              >
                {t("accordion.features.bestQuality.description")}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );

  function indexHandler(i) {
    const j = (index && i) === index ? -1 : i;
    setIndex(j);
  }
}

export default Accordion;
