import React, { useLayoutEffect } from "react";
import { Work } from ".";
import arCoke from "../assets/projects/coca.jpg";
import fifa from "../assets/projects/fifa1.png";
import nunet from "../assets/projects/nunet.png";
import block from "../assets/projects/block.png";
import sulala2 from "../assets/projects/sulalaone2.png";
import sha1 from "../assets/projects/owl.png";
import hotovo from "../assets/images/htv-meansdone-01.png";
import vin from "../assets/projects/vin.jpg";
import nodeImg from "../assets/images/stacks/icons8-nodejs-48.png";
import dockerImg from "../assets/images/stacks/docker.png";
import pythonImg from "../assets/images/stacks/Python.png";
import djangoImg from "../assets/images/stacks/django1.png";
import nextjsImg from "../assets/images/stacks/nextjs1.png";
import flutterImg from "../assets/images/stacks/flutter3.png";
import firebaseImg from "../assets/images/stacks/firebase.png";
import solidityImg from "../assets/images/stacks/solidty.png";
import goImg from "../assets/images/stacks/icons8-golang-48.png";
import reactImg from "../assets/images/stacks/icons8-react-40.png";
import puppeteerImg from "../assets/images/stacks/puppeteer.png";
import vueImg from "../assets/images/stacks/vue.png";
import mediaPipeImg from "../assets/images/stacks/mp.png";
import grafanaImg from "../assets/images/stacks/grafana.png";
import ethereumImg from "../assets/images/stacks/ethereum.png";
import chainlinkImg from "../assets/images/stacks/chainlink.png";
import sqlImg from "../assets/images/stacks/sql.png";
import apiImg from "../assets/images/stacks/api.png";
import mssqlImg from "../assets/images/stacks/mssql.png";
import angularImg from "../assets/images/stacks/angular.png";
import figmaImg from "../assets/images/stacks/figma.png";
import { useTranslation } from "react-i18next";

const urls = {
  angular: "https://angular.io/ ",
  node: "https://nodejs.org/en/",
  react: "https://reactjs.org/",
  firebase: "https://firebase.google.com/",
  mediaPipe: "https://mediapipe.dev/",
  puppeteer: "https://pptr.dev/",
  docker: "https://www.docker.com/",
  python: "https://www.python.org/",
  grafana: "https://grafana.com/",
  solidity: "https://docs.soliditylang.org/en/v0.8.12/",
  chainlink: "https://chain.link/",
  ethereum: "https://ethereum.org/en/",
  vuejs: "https://vuejs.org/",
  nestjs: "https://nestjs.com/",
  sheets: "https://sheets.google.com/",
  golang: "https://go.dev/",
  sqlserver: "https://www.microsoft.com/en-us/sql-server",
  typescript: "https://www.typescriptlang.org/",
  django: "https://www.djangoproject.com/",
  nextjs: "https://nextjs.org/",
  flutter: "https://flutter.dev/",
  figma: "https://www.figma.com/",
};

function WorkGroup() {
  const { t } = useTranslation();
  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".work");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele) => {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
    };
  }, []);
  const cardContent = [
    {
      imageTop: sulala2,
      title: t("projects.sulala.title"),
      description: t("projects.sulala.description"),
      imageIcons: [
        {
          img: flutterImg,
          title: "Flutter",
          url: urls.flutter,
        },
        {
          img: nextjsImg,
          title: "NextJS",
          url: urls.nextjs,
        },
        {
          img: djangoImg,
          title: "Django",
          url: urls.django,
        },
      ],
      category: "SaaS Development",
    },
    {
      imageTop: sha1,
      title: t("projects.owl.title"),
      description: t("projects.owl.description"),
      imageIcons: [
        {
          img: nodeImg,
          title: "NodeJS",
          url: urls.node,
        },
        {
          img: reactImg,
          title: "ReactJS",
          url: urls.react,
        },
        {
          img: dockerImg,
          title: "Docker",
          url: urls.docker,
        },
        {
          img: figmaImg,
          title: "Figma",
          url: urls.figma,
        },
      ],
      category: "Webapp Development",
    },
    {
      imageTop: arCoke,
      title: t("projects.coca.title"),
      description: t("projects.coca.description"),
      imageIcons: [
        {
          img: nodeImg,
          title: "",
          url: urls.node,
        },
        {
          img: reactImg,
          title: "ReactJS",
          url: urls.react,
        },

        {
          img: firebaseImg,
          title: "Firebase",
          url: urls.firebase,
        },
        {
          img: mediaPipeImg,
          title: "MediaPipe",
          url: urls.mediaPipe,
        },
      ],
      category: "Webapp Development",
      link: "coca-cola-trivia",
    },
    {
      imageTop: fifa,
      title: t("projects.fifa.title"),
      description: t("projects.fifa.description"),
      imageIcons: [
        {
          img: nodeImg,
          title: "",
          url: urls.node,
        },
        {
          img: puppeteerImg,
          title: "Puppeteer",
          url: urls.puppeteer,
        },
        {
          img: dockerImg,
          title: "Docker",
          url: urls.docker,
        },
        {
          img: firebaseImg,
          title: "Firebase",
          url: urls.firebase,
        },
      ],
      category: "Quality Assurance Development",
      link: "ea-fifa",
    },
    {
      imageTop: nunet,
      title: t("projects.synet.title"),
      description: t("projects.synet.description"),
      imageIcons: [
        {
          img: pythonImg,
          title: "Python",
          url: urls.python,
        },
        {
          img: dockerImg,
          title: "Docker",
          url: urls.docker,
        },
        {
          img: reactImg,
          title: "ReactJS",
          url: urls.react,
        },
        {
          img: grafanaImg,
          title: "Grafana",
          url: urls.grafana,
        },
      ],
      category: "SaaS Development",
      link: "synet-computing",
    },
    {
      imageTop: block,
      title: t("projects.prisma.title"),
      description: t("projects.prisma.description"),
      imageIcons: [
        {
          img: solidityImg,
          title: "Solidity",
          url: urls.solidity,
        },
        {
          img: chainlinkImg,
          title: "Chainlink",
          url: urls.chainlink,
        },
        {
          img: ethereumImg,
          title: "Ethereum",
          url: urls.ethereum,
        },
        {
          img: vueImg,
          title: "VueJS",
          url: urls.vuejs,
        },
      ],
      category: "SaaS Development",
      link: "blockchain-trade",
    },
    {
      imageTop: hotovo,
      title: t("projects.hotovo.title"),
      description: t("projects.hotovo.description"),
      imageIcons: [
        {
          img: nodeImg,
          title: "",
          url: urls.node,
        },
        {
          img: dockerImg,
          title: "Docker",
          url: urls.docker,
        },
        {
          img: angularImg,
          title: "Angular",
          url: urls.angular,
        },
        {
          img: apiImg,
          title: "API Integrations",
          url: "#",
        },
      ],
      category: "Webapp Development",
      link: "hotovo",
    },
    {
      imageTop: vin,
      title: t("projects.vin.title"),
      description: t("projects.vin.description"),
      imageIcons: [
        {
          img: sqlImg,
          title: "",
          url: "#",
        },
        {
          img: mssqlImg,
          title: "",
          url: urls.sqlserver,
        },
        {
          img: goImg,
          title: "GoLang",
          url: urls.golang,
        },
        {
          img: dockerImg,
          title: "Docker",
          url: urls.docker,
        },
      ],
      category: "API Development",
      link: "vin-encoder",
    },
  ];
  const contents = ["1", "2", "3", "4", "5", "6", "7", "8"];

  return (
    <div className="md:px-20">
      {/* <div
        style={{ animationDelay: 3 / 5 + "s" }}
        className="container mx-auto px-2 md:px-0  work group flex flex-col w-full rounded-lg opacity-0 overflow-hidden transition-shadow"
      ></div> */}
      <div className="container mx-auto px-2 md:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 py-16 lg:gap-8 lg:flex-wrap">
        {contents.map((x, i) => (
          <Work key={i} index={i + 1} content={cardContent[i]} />
        ))}
      </div>
    </div>
  );
}

export default WorkGroup;
