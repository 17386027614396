import { useLayoutEffect } from "react";

const useNavbarEffect = (initialBg = false, toggleOnScroll = true) => {
  useLayoutEffect(() => {
    const getNavbarElement = () => document.querySelector(".navbar");

    const applyInitialStyles = (nav) => {
      if (initialBg) {
        nav.classList.add("bg-primary");
      } else {
        nav.classList.remove("bg-primary");
      }
    };

    const handleScroll = () => {
      const nav = getNavbarElement();
      if (!nav) return;

      const top = 70;
      if (window.scrollY >= top) {
        nav.classList.add("bg-primary");
      } else if (toggleOnScroll) {
        nav.classList.remove("bg-primary");
      }
    };

    const setupNavbarEffect = () => {
      const nav = getNavbarElement();
      if (nav) {
        applyInitialStyles(nav);
        if (toggleOnScroll) {
          window.addEventListener("scroll", handleScroll);
        }
      } else {
        console.log("Navbar element not found, retrying...");
        setTimeout(setupNavbarEffect, 1000);
      }
    };

    setupNavbarEffect();

    return () => {
      if (toggleOnScroll) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [initialBg, toggleOnScroll]);
};

export default useNavbarEffect;
