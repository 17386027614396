import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ResponsiveTable = () => {
  const { t } = useTranslation();
  const models = [
    {
      title: t("models.cardsContent.extendTeam.title"),
      problem: t("models.cardsContent.extendTeam.details.problem"),
      input: t("models.cardsContent.extendTeam.details.input"),
      communication: t("models.cardsContent.extendTeam.details.communication"),

      value: [
        t("models.cardsContent.extendTeam.details.valueOne"),
        t("models.cardsContent.extendTeam.details.valueTwo"),
        t("models.cardsContent.extendTeam.details.valueThree"),
        t("models.cardsContent.extendTeam.details.valueFour"),
      ],
      action: t("models.cardsContent.extendTeam.details.action"),
    },
    {
      title: t("models.cardsContent.managedTeam.title"),
      problem: t("models.cardsContent.managedTeam.details.problem"),
      input: t("models.cardsContent.managedTeam.details.input"),
      communication: t("models.cardsContent.managedTeam.details.communication"),
      value: [
        t("models.cardsContent.managedTeam.details.valueOne"),
        t("models.cardsContent.managedTeam.details.valueTwo"),
        t("models.cardsContent.managedTeam.details.valueThree"),
        t("models.cardsContent.managedTeam.details.valueFour"),
      ],
      action: t("models.cardsContent.managedTeam.details.action"),
    },
    {
      title: t("models.cardsContent.customSolution.title"),
      problem: t("models.cardsContent.customSolution.details.problem"),
      input: t("models.cardsContent.customSolution.details.input"),
      communication: t(
        "models.cardsContent.customSolution.details.communication"
      ),
      value: [
        t("models.cardsContent.customSolution.details.valueOne"),
        t("models.cardsContent.customSolution.details.valueTwo"),
        t("models.cardsContent.customSolution.details.valueThree"),
        t("models.cardsContent.customSolution.details.valueFour"),
      ],
      action: t("models.cardsContent.customSolution.details.action"),
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextModel = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % models.length);
  };

  const previousModel = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? models.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="my-6 h-screen text-sm">
      <div className="max-w-7xl mx-auto">
        {/* Desktop View */}
        <div className="hidden lg:grid lg:grid-cols-[1fr,7fr]">
          {/* Header Column */}
          <div className="bg-white shadow-md border-r border-gray-300 ">
            <div
              className="p-4 border border-gray-700 h-[66px] flex items-start bg-primaryMedium
               "
            ></div>{" "}
            <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
              <h3 className="font-bold text-black">Problem to Solve</h3>
            </div>
            <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
              <h3 className="font-bold text-black">Input from the Client</h3>
            </div>
            <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
              <h3 className="font-bold text-black">Communication Structure</h3>
            </div>
            <div className="border-b border-gray-300 h-[190px] flex items-start px-4">
              <h3 className="font-bold text-black">Value Delivered by Mereb Tech</h3>
            </div>
            <div className="h-[60px] flex items-center px-4">
              <h3 className="font-bold text-black">Choose Your Model</h3>
            </div>
          </div>

          {/* Models */}
          <div className="grid grid-cols-3">
            {models.map((model, index) => (
              <div
                key={index}
                className="bg-white shadow-md border-l border-gray-300"
              >
                <div className="bg-primary text-white text-center font-bold h-[66px] flex items-center justify-center px-4">
                  {model.title}
                </div>
                <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
                  <p className="text-gray-600 text-sm">{model.problem}</p>
                </div>
                <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
                  <p className="text-gray-600 text-sm">{model.input}</p>
                </div>
                <div className="border-b border-gray-300 h-[160px] flex items-start px-4">
                  <p className="text-gray-600 text-sm">{model.communication}</p>
                </div>
                <div className="border-b border-gray-300 h-[190px] flex items-start px-4">
                  <ul className="text-gray-600 text-sm">
                    {model.value.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="h-[60px] flex items-center px-4">
                  <button
                    className="w-full bg-primary text-white py-2 rounded-md hover:bg-primaryMedium transition text-sm"
                    onClick={() => {
                      window.location.href = "/contact";
                    }}
                  >
                    {model.action}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Tablet View */}
        <div className="hidden md:grid md:grid-cols-[1fr,9fr]  lg:hidden">
          {/* Tablet Navigation Buttons */}
          <div className="flex justify-between mb-4 col-span-3">
            <button
              onClick={previousModel}
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white py-2 px-4 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-blue-500 hover:to-blue-700"
            >
              {"< "}
              {"<"} Previous
            </button>
            <button
              onClick={nextModel}
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white py-2 px-4 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-blue-500 hover:to-blue-700"
            >
              Next {">"} {">"}
            </button>
          </div>

          {/* Header Column */}
          <div className="bg-white shadow-md border-r border-gray-300 ">
            <div
              className="p-4 border border-gray-700 h-[66px] flex items-start bg-primaryMedium
               "
            ></div>{" "}
            <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
              <h3 className="font-bold text-black">Problem to Solve</h3>
            </div>
            <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
              <h3 className="font-bold text-black">Input from the Client</h3>
            </div>
            <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
              <h3 className="font-bold text-black">Communication Structure</h3>
            </div>
            <div className="p-4 border-b border-gray-300 h-[309px] flex items-start">
              <h3 className="font-bold text-black">Value Delivered by Mereb Tech</h3>
            </div>
            <div className="p-4 h-[80px] flex items-center">
              <h3 className="font-bold text-black">Choose Your Model</h3>
            </div>
          </div>

          {/* Models */}
          <div className="col-span-2 grid grid-cols-2 ">
            {[
              models[currentIndex],
              models[(currentIndex + 1) % models.length],
            ].map((model, index) => (
              <div
                key={index}
                className="bg-white shadow-md border border-gray-300"
              >
                <div className="bg-primary text-white text-center font-bold p-4 h-[66px] flex items-center justify-center">
                  {model.title}
                </div>
                <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
                  <p className="text-gray-600">{model.problem}</p>
                </div>
                <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
                  <p className="text-gray-600">{model.input}</p>
                </div>
                <div className="p-4 border-b border-gray-300 h-[216px] flex items-start">
                  <p className="text-gray-600">{model.communication}</p>
                </div>
                <div className="p-4 border-b border-gray-300 h-[309px] flex items-start">
                  <ul className="list-disc list-inside custom-list-marker">
                    {model.value.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="p-4 h-[80px] flex items-center">
                  <button
                    className="w-full bg-primary text-white py-2 rounded-md hover:bg-primaryMedium transition
                  "
                    onClick={() => {
                      window.location.href = "/contact";
                    }}
                  >
                    {model.action}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Mobile View */}
        <div className="md:hidden">
          {/* Mobile Navigation Buttons */}
          <div className="flex justify-between  mb-4 col-span-3">
            <button
              onClick={previousModel}
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white py-2 px-4 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-blue-500 hover:to-blue-700"
            >
              {"< "}
              {"<"} Previous
            </button>
            <button
              onClick={nextModel}
              className="bg-gradient-to-r from-blue-400 to-blue-600 text-white py-2 px-4 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105 hover:from-blue-500 hover:to-blue-700"
            >
              Next {">"} {">"}
            </button>
          </div>
          <div className="flex flex-row">
            {/* Header */}
            <div className="bg-white shadow-md border-r border-gray-300 w-[30%]">
              <div
                className="p-4 border border-gray-700 h-[66px] flex items-start bg-primaryMedium
               "
              ></div>
              <div className="py-4 border-b border-gray-300 h-[236px] flex items-start">
                <h3 className="font-bold text-black">Problem to Solve</h3>
              </div>
              <div className="py-4 border-b border-gray-300 h-[236px] flex items-start">
                <h3 className="font-bold text-black">Input from the Client</h3>
              </div>
              <div className="py-4 border-b border-gray-300 h-[236px] flex items-start">
                <h3 className="font-bold text-black">
                  Communication Structure
                </h3>
              </div>
              <div className="py-4 border-b border-gray-300 h-[279px] flex items-start">
                <h3 className="font-bold text-black">
                  Value Delivered by Mereb Tech
                </h3>
              </div>
              <div className="py-4 h-[60px] flex items-center">
                <h3 className="font-bold text-black">Choose Your Model</h3>
              </div>
            </div>

            {/* Active Model */}
            <div className="bg-white shadow-md border-l border-gray-300 ">
              <div className="bg-primary text-white text-center font-bold p-4 h-[66px] flex items-center justify-center">
                {models[currentIndex].title}
              </div>
              <div className="p-4 border-b border-gray-300 h-[236px] flex items-start">
                <p className="text-gray-600">{models[currentIndex].problem}</p>
              </div>
              <div className="p-4 border-b border-gray-300 h-[236px] flex items-start">
                <p className="text-gray-600">{models[currentIndex].input}</p>
              </div>
              <div className="p-4 border-b border-gray-300 h-[236px] flex items-start">
                <p className="text-gray-600">
                  {models[currentIndex].communication}
                </p>
              </div>
              <div className="p-4 border-b border-gray-300 h-[279px] flex items-start">
                <ul className="list-disc list-inside text-gray-600">
                  {models[currentIndex].value.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="p-4 h-[60px] flex items-center">
                <button
                  className="w-full bg-primary text-white py-2 rounded-md hover:bg-primaryMedium transition"
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                >
                  {models[currentIndex].action}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTable;
