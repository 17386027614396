import React from "react";
import Flickity from "react-flickity-component";
import { useTranslation } from "react-i18next";

const flickityOptions = {
  initialIndex: 1,
  prevNextButtons: false,
  wrapAround: true,
  draggable: true,
};

function Testimonials() {
  const { t } = useTranslation();
  return (
    <div className="pb-32 lg:px-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 mt-16">
          <h2 className="text-primary font-bold font-heading text-2xl md:text-4xl mb-5 capitalize">
            {t("testimonials.title")}
          </h2>
        </div>
      </div>
      <div className="relative container mx-auto pt-8 outline-none">
        <Flickity
          className={"carousel"}
          elementType={"div"}
          options={flickityOptions}
          disableImagesLoaded={false}
        >
          <div className="carousel-cell mr-6">
            <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg px-8 py-6 text-center">
              <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                {t("testimonials.reviews.reviewOne.review")}
              </p>
              <div>
                <h2 className="font-bold mt-4 text-xl font-heading text-gray-700">
                  Brogan Wells
                </h2>
                <p className="text-gray-500 font-heading font-bold">
                  {t("testimonials.reviews.reviewOne.position")}
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-cell mr-12">
            <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg  px-8 py-6 text-center">
              <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                {t("testimonials.reviews.reviewTwo.review")}
              </p>
              <div>
                <h2 className="font-bold mt-4 text-xl font-heading text-gray-700">
                  Eric Jackson
                </h2>
                <p className="text-gray-500 font-heading font-bold">
                  {t("testimonials.reviews.reviewTwo.position")}
                </p>
              </div>
            </div>
          </div>
          <div className="carousel-cell mr-12">
            <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg  px-8 py-6 text-center">
              <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                {t("testimonials.reviews.reviewThree.review")}
              </p>
              <div>
                <h2 className="mt-4 text-xl font-heading text-gray-700 font-bold">
                  David Christensen
                </h2>
                <p className="text-gray-500 font-heading font-bold">
                  {t("testimonials.reviews.reviewThree.position")}
                </p>
              </div>
            </div>
          </div>
        </Flickity>
      </div>
    </div>
  );
}

export default Testimonials;
