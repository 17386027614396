import React, { useEffect } from "react";
import { WorkGroup, CommonHeader } from "../components";
import tabs from "../assets/images/tabs.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";
import { useTranslation } from "react-i18next";

function PortfolioPage() {
  const { t } = useTranslation();
  useNavbarEffect(true, false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={t("projects.title")}
      subTitle={t("projects.description")}
      src={tabs}
      alt={"Tabs illustration"}
    >
      <WorkGroup />
    </CommonHeader>
  );
}

export default PortfolioPage;
