import React, { useEffect } from "react";
import personal from "../assets/images/personal.svg";
import useNavbarEffect from "../hooks/useNavbarEffect";
import CommonHeader from "../components/CommonHeader";
import { useTranslation } from "react-i18next";

function PrivacyPage() {
  const { t } = useTranslation();
  useNavbarEffect(true, false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <CommonHeader
      title={t("privacyPage.header.title")}
      subTitle=""
      src={personal}
      alt={t("privacyPage.header.imageAlt")}
    >
      <div className="mt-10 px-4 md:px-4 container relative mx-auto md:mt-16 sm:px-0 sm:mt-5 md:text-left">
        <p className="text-grey-600 mt-3 text-sm md:text-base font-body">
          {t("privacyPage.sections.introduction.textOne")}
        </p>
        <p className="text-grey-600 mt-3 text-sm md:text-base font-body">
          {t("privacyPage.sections.introduction.textTwo")}
        </p>
      </div>

      {[
        "informationCollection",
        "logData",
        "cookies",
        "serviceProviders",
        "security",
        "linksToOtherSites",
        "changesToNotice",
        "contactUs",
      ].map((sectionKey) => (
        <div
          key={sectionKey}
          className="mt-10 mb-10 px-4 md:px-4 container relative mx-auto md:mt-8 sm:px-0 sm:mt-5 md:text-left"
        >
          <h2 className="uppercase text-primary text-2xl md:text-3xl font-bold font-heading">
            {t(`privacyPage.sections.${sectionKey}.title`)}
          </h2>
          {sectionKey === "serviceProviders" ? (
            <>
              <p className="text-grey-600 mt-3 text-sm md:text-base font-body">
                {t("privacyPage.sections.serviceProviders.text")}
              </p>
              <ul className="list-disc mx-5">
                {["reasonOne", "reasonTwo", "reasonThree", "reasonFour"].map(
                  (reasonKey) => (
                    <li
                      key={reasonKey}
                      className="text-grey-600 mt-3 text-sm md:text-base font-body"
                    >
                      {t(
                        `privacyPage.sections.serviceProviders.reasons.${reasonKey}`
                      )}
                    </li>
                  )
                )}
              </ul>
              <p className="text-grey-600 mt-3 text-sm md:text-base font-body">
                {t("privacyPage.sections.serviceProviders.additionalText")}
              </p>
            </>
          ) : (
            <>
              {Array.isArray(
                t(`privacyPage.sections.${sectionKey}.text`, {
                  returnObjects: true,
                })
              ) ? (
                t(`privacyPage.sections.${sectionKey}.text`, {
                  returnObjects: true,
                }).map((text, idx) => (
                  <p
                    key={idx}
                    className="text-grey-600 mt-3 text-sm md:text-base font-body"
                  >
                    {text}
                  </p>
                ))
              ) : (
                <p className="text-grey-600 mt-3 text-sm md:text-base font-body">
                  {t(`privacyPage.sections.${sectionKey}.text`)}
                </p>
              )}
            </>
          )}
        </div>
      ))}
    </CommonHeader>
  );
}

export default PrivacyPage;
