import React, { useLayoutEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogOneImage from "../assets/images/c.jpg";
import blogTwoImage from "../assets/images/b.jpg";
import blogThreeImage from "../assets/images/code-2.png";
import blogFourImage from "../assets/images/code.jpg";
import { useTranslation } from "react-i18next";

function BlogCardGroup() {
  const { t } = useTranslation();
  const blogFiles = [
    {
      file: "/assets/blogs/sulala_project.md",
      imageTop: blogOneImage,
      tags: [t("insights.tags.caseStudy")],
    },
    {
      file: "/assets/blogs/Overcoming_Challenges_in_implementing_AR.md",
      imageTop: blogTwoImage,
      tags: [t("insights.tags.caseStudy")],
    },
    {
      file: "/assets/blogs/poker_optimization.md",
      imageTop: blogThreeImage,
      tags: [t("insights.tags.caseStudy")],
    },
    {
      file: "/assets/blogs/poker_enhance_user_engagement.md",
      imageTop: blogFourImage,
      tags: [t("insights.tags.caseStudy")],
    },
  ];

  const tags = [t("insights.tags.caseStudy")];
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTagSelection = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  const filteredBlogs =
    selectedTags.length === 0
      ? blogFiles
      : blogFiles.filter((blog) =>
          blog.tags.some((tag) => selectedTags.includes(tag))
        );

  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".blog-card");
    const observer = new IntersectionObserver((entries) => {
      for (const ele of entries) {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      }
    });
    for (const card of cards) {
      observer.observe(card);
    }
    return () => {
      for (const card of cards) {
        observer.disconnect(card);
      }
    };
  }, [filteredBlogs]);

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="flex justify-start space-x-4 mb-8">
        {tags.map((tag) => (
          <button
            key={tag}
            onClick={() => toggleTagSelection(tag)}
            className={`px-4 py-2 border ${
              selectedTags.includes(tag) ? "bg-blue-500 text-white" : ""
            }`}
          >
            {tag}
          </button>
        ))}
      </div>

      {filteredBlogs.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 lg:gap-8">
          {filteredBlogs.map((content, i) => (
            <BlogCard
              key={i.toString()}
              index={i + 1}
              file={content.file}
              imageTop={content.imageTop}
              tag={content.tags[0]}
            />
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-500 text-xl">Coming Soon</div>
      )}
    </div>
  );
}

export default BlogCardGroup;
